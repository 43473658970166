export const Api = {
	LOGIN: 'user/public/login/admin',
	SIGNUP: 'user/public/signup/admin',
	REFRESH_TOKEN: 'user/auth/refresh',
	SEND_OTP: 'user/public/forget-password',
	RESET_PASSWORD: 'user/public/verify-otp',
	UPDATE_FIREBASE_TOKEN: 'settings/update',
	CREATE_FIREBASE_TOKEN: 'settings/create',

	LOOKUP_GOVERNORATES: 'geometry/public/governorate',
	LOOKUP_CITIES: 'geometry/public/city',

	SELLER_CATEGORIES: 'category/public/list',

	ADMIN_CATEGORIES: 'category/admin/list',
	ADD_CATEGORY: 'category/admin/create',
	DELETE_CATEGORY: 'category/admin/delete',
	GET_CATEGORY: 'category/admin/get',
	EDIT_CATEGORY: 'category/admin/update',
	UPLOAD_CATEGORY_IMAGE: 'category/admin/upload/local',

	All_SELLER_ATTRIBUTES: 'attribute/seller/list',
	All_ADMIN_ATTRIBUTES: 'attribute/admin/list',
	ADD_ATTRIBUTE: 'attribute/admin/create',
	DELETE_ATTRIBUTE: 'attribute/admin/delete',
	GET_ATTRIBUTE: 'attribute/admin/get',
	EDIT_ATTRIBUTE: 'attribute/admin/update',

	UPLOAD_PROFILE_IMAGE: 'user/auth/profile/upload/local',
	GET_PROFILE: 'user/auth/profile',
	UPDATE_PROFILE: 'user/auth/profile/update',
	UPDATE_PASSWORD: 'user/auth/change-password',

	// Admin kitchen
	Get_ADMIN_KITCHEN: 'kitchen/admin/get',
	
	//Seller kitchen
	ADD_KITCHEN: 'kitchen/seller/create',
	ALL_KITCHENS: 'kitchen/admin/list',
	Get_KITCHEN: 'kitchen/seller/get',
	UPDATE_KITCHEN: 'kitchen/seller/update',
	UPLOAD_KITCHEN_IMAGE: 'kitchen/seller/upload/single',
	UPLOAD_KITCHEN_GALLARY: 'kitchen/seller/upload/multi',

	//Seller product
	ADD_PRODUCT: 'product/seller/create',
	ALL_Seller_PRODUCTS: 'product/seller/list',
	Get_PRODUCT: 'product/seller/get',
	UPDATE_PRODUCT: 'product/seller/update',
	UPLOAD_MAIN: 'product/seller/upload/single',
	UPLOAD_GALLARY: 'product/seller/upload/multi',
	DELETE_PRODUCT: 'product/seller/delete',

	//Admin product
	ALL_ADMIN_PRODUCTS: 'product/admin/list',

	//Seller Orders
	ALL_SELLER_ORDERS: 'order/seller/list',
	ALL_AAMIN_ORDERS: 'order/admin/list',
	GET_ORDER_DETAILS: 'order/seller/get',
	UPDATE_ORDER_STATUS : 'order/seller/update',
	
	//Seller Special-Orders
	SELLER_SPECIAL_ORDERS: 'special-order/seller/list',
	SPECIAL_ORDER_DETAILS: 'special-order/seller/get',
	ADD_SPECIAL_OFFER: 'special-order/seller/add-offer',

	// Admin Orders 
	ALL_ORDERS : "order/admin/list",

	// Offer Seller
	SELLER_OFFERS: 'offer/seller/list',
	ADD_OFFER: 'offer/seller/create',
	DELETE_OFFER: 'offer/seller/delete',
	GET_OFFER: 'offer/seller/get',
	EDIT_OFFER: 'offer/seller/update',
	UPLOAD_OFFER: 'offer/seller/upload/single',

	SELLER_GET_PAYMENTS: 'payment/seller/get',
	SELLER_LIST_PAYMENTS: 'payment/seller/list',
	SELLER_PAYMENTS_STATISTICS: 'payment/seller/statistics',
	SELLER_REQUEST_PAYMENTS: 'payment/seller/request'
};
