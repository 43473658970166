import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { IPagination } from '../../../utility/interfaces/pagination.interface'
import { addSpecialOfferAction, getSpecialOrderDetailsAction, listSellerSpecialOrdersAction } from '../actions/special-orders.actions'
import { ISpecialOrder } from '../interfaces/special-order.interface'

export const SuccessAlert = withReactContent(Swal)

interface DataReducer {
  specialOrders: Array<ISpecialOrder>
  details: ISpecialOrder
  status: REDUX_STATUS
  query: IPagination
  _metadata: {
    totalPage: number
    total: number
  }
}

const initialState: DataReducer = {
  specialOrders: [],
  details: {} as ISpecialOrder,
  status: REDUX_STATUS.IDLE,
  query: {
    page: 1,
    perPage: 12
  },
  _metadata: {
    totalPage: 0,
    total: 0
  }
}

const specialOrderSlice = createSlice({
  name: 'special-order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listSellerSpecialOrdersAction.pending, (state) => {
        state.status = REDUX_STATUS.PENDING
      })
      .addCase(listSellerSpecialOrdersAction.fulfilled, (state, action) => {
        state.status = REDUX_STATUS.SUCCEEDED
        state.specialOrders = action.payload.data
      })
      .addCase(listSellerSpecialOrdersAction.rejected, (state, action: any) => {
        state.status = REDUX_STATUS.FAILED
        SuccessAlert.fire({ position: 'center', icon: 'error', title: 'Error happened!', showConfirmButton: !0, timer: 5000 })
      })
      
      .addCase(getSpecialOrderDetailsAction.pending, (state) => {
      })
      .addCase(getSpecialOrderDetailsAction.fulfilled, (state, action) => {
        state.details = action.payload.data
      })
      .addCase(getSpecialOrderDetailsAction.rejected, (state, action: any) => {
        SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
      
      .addCase(addSpecialOfferAction.pending, () => {})
      .addCase(addSpecialOfferAction.fulfilled, (state, action) => {
        SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
      .addCase(addSpecialOfferAction.rejected, (state, action: any) => {
        SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
  }
})

export const orderActions = specialOrderSlice.actions
export default specialOrderSlice.reducer
