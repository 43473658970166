import React from "react"
import { useTranslation } from "react-i18next";
import { ICartProduct, IOrder } from "../interfaces/order.interface";
import { Translation } from "../../../utility/helpers/translation.helper";
import { DISCOUNT_TYPE } from "../../../utility/enums/discount-type.enum";
import { IAddon } from "../../products/interface/product.interface";
import { Link } from "react-router-dom";

interface INormalOrderTable {
	details: IOrder
}

export const NormalOrderTableComponent: React.FC<INormalOrderTable> = ({details}) => {
	const assets = process.env.REACT_APP_ASSETS_URL
  const { t } = useTranslation()

  return (
    <table className="table table-nowrap align-middle table-borderless mb-0">
      <thead className="table-light text-muted text-start">
        <tr>
          <th scope="col">{t('ProductDetails')}</th>
          <th scope="col">{t('Price')}</th>
          <th scope="col">{t('Quantity')}</th>
          <th scope="col">{t('DiscountType')}</th>
          <th scope="col">{t('DiscountValue')}</th>
          <th scope="col">{t('Addons')}</th>
          <th scope="col">{t('Total')}</th>
        </tr>
      </thead>
      <tbody className="text-start">
        {details.cart?.products.map((product: ICartProduct) => (
          <tr key={product.option?.value}>
            <td>
              <div className="d-flex">
                <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
                  <img src={assets + product.mainImage} alt={product.name.En} className="img-fluid d-block" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="fs-15">
                    <Link to={"/products/view/" + product._id} className="link-primary">
                      {Translation(product.name)}
                    </Link>
                  </h5>
                  <p className="text-muted mb-0">
                    {Translation(product.attribute?.name)}: <span className="fw-medium">{product.option?.value}</span>
                  </p>
                </div>
              </div>
            </td>
            <td>{product.option?.price}</td>
            <td>{product.option?.quantity}</td>
            <td>{t(product.option?.discountType)}</td>
            <td>
              {product.option?.discountValue} {product.option?.discountType == DISCOUNT_TYPE.FIXED ? t('CurrencyCode') : '%'}
            </td>
            <td>{product.addons.map((addon: IAddon) => (<span key={addon.code}>{Translation(addon.name)} ({addon.price} {t("CurrencyCode")})</span>))}</td>
            <td className="fw-medium text-end">
              {product.option?.price * product.option?.quantity} {t('CurrencyCode')}
            </td>
          </tr>
        ))}
        <tr className="border-top border-top-dashed">
          <td colSpan={3}></td>
          <td colSpan={3} className="fw-medium p-0">
            <table className="table table-borderless mb-0">
              <tbody>
                <tr>
                  <td>{t("Sub_Total")} :</td>
                  <td className="text-end">
                    {details.cart?.totalPrice} {t('CurrencyCode')}
                  </td>
                </tr>
                {details.paymentInfo?.offer && (
                  <tr>
                    <td>
                      {t('Offer')} <span className="text-muted">({Translation(details.paymentInfo?.offer?.name)})</span> :
                    </td>
                    {details.paymentInfo?.offer?.discountType == DISCOUNT_TYPE.FIXED && (
                      <td className="text-end">
                        -{details.paymentInfo?.offer?.discountValue} {t('CurrencyCode')}
                      </td>
                    )}
                    {details.paymentInfo?.offer?.discountType == DISCOUNT_TYPE.PERCENT && (
                      <td className="text-end">
                        -{(details.paymentInfo?.offer?.discountValue / 100) * details.cart?.totalPrice} {t('CurrencyCode')}
                      </td>
                    )}
                  </tr>
                )}
                <tr>
                  <td>{t('DeliveryCharge')} :</td>
                  <td className="text-end">
                    {details.paymentInfo?.deliveryFee} {t('CurrencyCode')}
                  </td>
                </tr>
                <tr>
                  <td>{t('Tax')} :</td>
                  <td className="text-end">
                    {details.paymentInfo?.tax} {t('CurrencyCode')}
                  </td>
                </tr>
                <tr className="border-top border-top-dashed">
                  <th scope="row">{t('Total')}:</th>
                  <th className="text-end">
                    {details.paymentInfo?.totalPrice} {t('CurrencyCode')}
                  </th>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
