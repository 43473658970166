import { createSlice } from '@reduxjs/toolkit'
import { City, Governorate } from '../interfaces/lookup.interface'
import { getAllCitiesAction, getAllGovernoratesAction } from '../actions/lookup.action'

interface DataReducer {
  governorates: Governorate[] | null
  cities: City[] | null
  status: string
}

const initialState: DataReducer = {
  governorates: null,
  cities: null,
  status: 'idle',
}

const lookupSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllGovernoratesAction.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getAllGovernoratesAction.fulfilled, (state, action) => {
        state.governorates = action.payload.data
      })
      .addCase(getAllGovernoratesAction.rejected, (state, action: any) => {
        state.status = 'faild'
      })

      .addCase(getAllCitiesAction.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getAllCitiesAction.fulfilled, (state, action) => {
        state.cities = action.payload.data
      })
      .addCase(getAllCitiesAction.rejected, (state, action: any) => {
        state.status = 'faild'
      })
  }
})

export const lookupActions = lookupSlice.actions
export default lookupSlice.reducer
