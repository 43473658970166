import React from 'react'

const DashboardPage: React.FC<any> = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Starter</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a>Pages</a>
                </li>
                <li className="breadcrumb-item active">Starter</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
			<div className="row">
				<div className="col-12"></div>
			</div>
    </div>
  )
}

export default DashboardPage
