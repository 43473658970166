import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	addCategoryAction,
	editCategoryAction,
	getAdminCategoriesAction,
	getSellerCategoriesAction,
	getCategoryByIdAction,
	uploadImageAction
} from '../action/Category.action';
import { ICategory } from '../interface/Category.interface';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';

export const SuccessAlert = withReactContent(Swal);

interface DataReducer {
	categories: ICategory[];
	status: REDUX_STATUS;
}

const initialState: DataReducer = {
	categories: [],
	status: REDUX_STATUS.IDLE
};

const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
		setStatus(state: DataReducer, action: PayloadAction<REDUX_STATUS>) {
			state.status = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAdminCategoriesAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(getAdminCategoriesAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				state.categories = action.payload.data;
			})
			.addCase(getAdminCategoriesAction.rejected, (state, action: any) => {
				state.status = REDUX_STATUS.FAILED;
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(getSellerCategoriesAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(getSellerCategoriesAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				state.categories = action.payload.data;
			})
			.addCase(getSellerCategoriesAction.rejected, (state, action: any) => {
				state.status = REDUX_STATUS.FAILED;
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(addCategoryAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
				state.status = REDUX_STATUS.IDLE;
			})
			.addCase(addCategoryAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(getCategoryByIdAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(editCategoryAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
				state.status = REDUX_STATUS.IDLE;
			})
			.addCase(editCategoryAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})

			.addCase(uploadImageAction.fulfilled, (state, action) => {
				SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
				state.status = REDUX_STATUS.IDLE;
			})
			.addCase(uploadImageAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			});
	}
});

export const categoryActions = categorySlice.actions;
export default categorySlice.reducer;
