import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPagination } from '../../../utility/interfaces/pagination.interface';
import { getSellerPaymentStatisticsService, listSellerPaymentsApi, requestSellerPaymentsApi } from '../services/payments.service';

export const listSellerPendingPaymentsAction = createAsyncThunk('listSellerPendingPaymentsAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await listSellerPaymentsApi(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getSellerPaymentStatisticsAction = createAsyncThunk('getSellerPaymentStatisticsAction/get', async (_, thunkApi) => {
	try {
		const response = await getSellerPaymentStatisticsService();
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const listSellerProcessingPaymentsAction = createAsyncThunk('listSellerProcessingPaymentsAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await listSellerPaymentsApi(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const listSellerClosedPaymentsAction = createAsyncThunk('listSellerClosedPaymentsAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await listSellerPaymentsApi(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const requestSellerPaymentsAction = createAsyncThunk('requestSellerPaymentsAction/get', async (_, thunkApi) => {
	try {
		const response = await requestSellerPaymentsApi();
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
