import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/store';
import { editCategoryAction, getCategoryByIdAction, uploadImageAction } from '../action/Category.action';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { categoryActions } from '../slice/Category.slice';
import { validate } from '../../../utility/helpers/form-validation.helper';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../provider/IsLoading.provider';

const CategoryEditPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL;
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { setLoading } = useLoading();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({ nameEn: '', nameAr: '', isActive: true, icon: '' });
	const [preview, setPreview] = useState('/assets/images/placeholder.png');
	const [imageFile, setImageFile] = useState('');
	const params = useParams();
	const breadcrumb: Breadcrumb = {
		title: t('Categories'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Categories'), path: '/categories/list' },
			{ name: t('Edit'), path: '/categories/edit/' + params.id }
		]
	};

	useEffect(() => {
		setLoading(true)
		dispatch(getCategoryByIdAction(params.id as string)).then((res: any) => {
			const category = res.payload.data;
			setFormData({
				nameAr: category.name.Ar,
				nameEn: category.name.En,
				isActive: category.isActive,
				icon: category.icon,
			});
			setPreview(assets + category.icon);
			setLoading(false)
		});
	}, [dispatch, params]);

	const handleChange = (event: any) => {
		const { name, value } = event.target;
		if (name == 'isActive') {
			setFormData((prevFormData) => ({ ...prevFormData, [name]: JSON.parse(value) }));
		} else {
			setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
		}
	};

	const uploadhandler = (e: any) => {
		const reader = new FileReader();
		reader.onloadend = function () {
			setPreview(reader.result! as string);
		};
		reader.readAsDataURL(e.target.files[0]);
		setImageFile(e.target.files[0]);
	};

	const submitHandler = (e: any) => {
		setLoading(true)
		e.preventDefault();
		e.stopPropagation();
		validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
			if (validate) {
				const postData = {
					name: {
						En: formData.nameEn,
						Ar: formData.nameAr
					},
					isActive: formData.isActive
				};
				dispatch(editCategoryAction({ id: params.id as string, formData: postData })).then((response: any) => {
					if (imageFile != '') {
						const data = new FormData();
						data.append('file', imageFile);
						dispatch(uploadImageAction({ id: response.payload.data._id, image: data })).then(() => {
							setLoading(false)
							navigate('/categories/list');
						});
					} else {
						setLoading(false)
						navigate('/categories/list');
					}
				});
			}
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<form className="needs-validation" onSubmit={submitHandler} noValidate>
				<div className="row">
					<div className="col-lg-8">
						<div className="card">
							<div className="card-header">
								<ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
									<li className="nav-item">
										<a className="nav-link active" data-bs-toggle="tab" href="#english" role="tab">
											{t('English')}
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-bs-toggle="tab" href="#arabic" role="tab">
											{t('Arabic')}
										</a>
									</li>
								</ul>
							</div>
							<div className="card-body">
								<div className="tab-content">
									<div className="row">
										<div className="col-md-6">
											<div className="mb-3">
												<label className="form-label" htmlFor="nameAr">
													{t('NameAr')}
												</label>
												<input
													type="text"
													className="form-control"
													id="nameAr"
													name="nameAr"
													value={formData.nameAr}
													onChange={(e) => handleChange(e)}
													placeholder={t('NameAr')}
													required
												/>
												<div className="invalid-feedback">{t('NameError')}</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="form-label" htmlFor="nameEn">
													{t('NameEn')}
												</label>
												<input
													type="text"
													className="form-control"
													id="nameEn"
													name="nameEn"
													value={formData.nameEn}
													onChange={(e) => handleChange(e)}
													placeholder={t('NameEn')}
													required
												/>
												<div className="invalid-feedback">{t('NameError')}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="text-end mb-3">
							<button type="submit" className="btn btn-success w-sm">
								{t('Edit')}
							</button>
						</div>
					</div>

					<div className="col-lg-4">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('MainImage')}</h5>
							</div>
							<div className="card-body">
								<div className="mb-4">
									<div className="text-center">
										<div className="position-relative d-inline-block">
											<div className="position-absolute top-100 start-100 translate-middle">
												<label htmlFor="category-image" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Select Image">
													<div className="avatar-xs">
														<div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
															<i className="ri-image-fill"></i>
														</div>
													</div>
												</label>
												<input
													className="form-control d-none"
													id="category-image"
													type="file"
													accept="image/png, image/gif, image/jpeg"
													onChange={uploadhandler}
												/>
											</div>
											<div className="avatar-xl">
												<div className="avatar-title bg-light rounded">
													<img src={preview} id="category-img" className="avatar-xl h-auto" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('Status')}</h5>
							</div>
							<div className="card-body">
								<div className="mb-3">
									<div className="form-check-inline mb-2">
										<input
											className="form-check-input"
											type="radio"
											name="isActive"
											id="active"
											value="true"
											checked={formData.isActive}
											onChange={handleChange}
										/>
										<label className="form-check-label me-2" htmlFor="active">
											{t('Active')}
										</label>
									</div>
									<div className="form-check-inline mb-2">
										<input
											className="form-check-input"
											type="radio"
											name="isActive"
											id="inactive"
											value="false"
											checked={!formData.isActive}
											onChange={handleChange}
										/>
										<label className="form-check-label me-2" htmlFor="inactive">
											{t('InActive')}
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default CategoryEditPage;
