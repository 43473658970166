import React, { createContext, useContext } from 'react'
import { useAppSelector } from '../store/store'
import { IUser } from '../modules/profile/interface/User.interface'

const initValue: IUser = {} as IUser

const ProfileContext = createContext(initValue)

export const ProfileProvider = ({ children }: any) => {
  const { contents } = useAppSelector((state) => state.profile)

  return <ProfileContext.Provider value={contents} key={contents._id}>{children}</ProfileContext.Provider>
}

export const  useProfile = () => {
  const context = useContext(ProfileContext)
  if (!context) {
    throw new Error('useProfile must be used within ProfileProvider')
  }
  return context
}
