import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { loginAction, resetPasswordAction, sendOTPAction, updateFirebaseTokenAction } from '../action/auth.action';
import { useTranslation } from 'react-i18next';
import { requestForToken } from '../../../firebase';
import { validate } from '../../../utility/helpers/form-validation.helper';
import { useLoading } from '../../../provider/IsLoading.provider';

const ForgetPasswordPage: React.FC<any> = () => {
	const router = useNavigate();
	const { setLoading } = useLoading();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const auth = useAppSelector((state) => state.auth.contents);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [email, setEmail] = useState('');
	const [otp, setOtp] = useState('');
	const { isOTP } = useAppSelector((state) => state.auth);

	useEffect(() => {
		if (auth.accessToken) {
			router('/');
		}
	}, [auth, router]);

	const comparePasswords = (pass: string | null, confirmPass: string | null): boolean => {
		if (pass !== confirmPass) {
			return false;
		} else {
			return true;
		}
	};

	const sendOTPHandler = (e: any) => {
		validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
			if (validate) {
				setLoading(true);
				e.preventDefault();
				dispatch(sendOTPAction({ email })).then((res: any) => {
					if (res.payload.success) {

						setLoading(false);
					}
				});
			}
		});
	};

	const forgetPasswordHandler = (e: any) => {
		validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
			if (validate) {
				const isEqual: boolean = comparePasswords(password, confirmPassword);
				if (isEqual) {
					setLoading(true);
					e.preventDefault();
					dispatch(resetPasswordAction({ email, password, otp })).then((res: any) => {
						if (res.payload.success) {
							setLoading(false);
						}
					});
				}
			}
		});
	};

	return (
		<div className="auth-page-wrapper pt-5">
			<Helmet>
				<script src="/assets/js/pages/particles.app.js" async></script>
				<script src="/assets/js/pages/password-addon.init.js"></script>
			</Helmet>
			<div className="auth-one-bg-position auth-one-bg" id="auth-particles">
				<div className="bg-overlay"></div>

				<div className="shape">
					<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
						<path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
					</svg>
				</div>
			</div>

			<div className="auth-page-content">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="text-center mt-sm-5 mb-4 text-white-50">
								<div>
									<Link to="/" className="d-inline-block auth-logo">
										<img src="/assets/images/logos/akla-beity-logoline.png" alt="akla beity logo" height="60" />
									</Link>
								</div>
								<p className="mt-3 fs-15 fw-medium">{t('Intro')}</p>
							</div>
						</div>
					</div>

					<div className="row justify-content-center">
						<div className="col-md-8 col-lg-6 col-xl-5">
							<div className="card mt-4">

								{!isOTP ?
									<div className="card-body p-4">
										<div className="text-start mt-2">
											<h6 className="text-primary">{t('Send_OTP_Intro')}</h6>
										</div>
										<div className="p-2 mt-4">
											<form autoComplete="off" className="needs-validation" noValidate>
												<div className="mb-3">
													<label htmlFor="email" className="form-label">
														{t('EmailAddress')}
													</label>
													<input
														type="email"
														className="form-control"
														id="email"
														placeholder={t('EmailAddress')}
														value={email}
														onChange={(e) => setEmail(e.target.value)}
														required
													/>
													<div className="invalid-feedback">{t('EmailError')}</div>
												</div>

												<div className="mt-4">
													<button className="btn btn-success w-100" type="button" onClick={sendOTPHandler}>
														{t('Send')}
													</button>
												</div>
											</form>
										</div>
									</div>
									:
									<div className="card-body p-4">
										<div className="p-2 mt-4">
											<form autoComplete="off" className="needs-validation" noValidate>
												<div className="mb-3">
													<label htmlFor="email" className="form-label">
														{t('OTP')}
													</label>
													<input
														type="text"
														className="form-control"
														id="otp"
														placeholder={t('OTP')}
														value={otp}
														onChange={(e) => setOtp(e.target.value)}
														required
													/>
													<div className="invalid-feedback">{t('EmailError')}</div>
												</div>

												<div className="mb-3">
													<label className="form-label" htmlFor="password">
														{t('Password')}
													</label>
													<div className="position-relative auth-pass-inputgroup mb-3">
														<input
															type="password"
															className="form-control pe-5 password-input"
															placeholder={t('Password')}
															id="password"
															value={password}
															onChange={(e) => setPassword(e.target.value)}
														/>
														<button
															className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
															type="button"
															id="password-addon">
															<i className="ri-eye-fill align-middle"></i>
														</button>
													</div>
												</div>

												<div className="mb-3">
													<label className="form-label" htmlFor="confirmPassword">
														{t('ConfirmPassword')}
													</label>
													<div className="position-relative auth-pass-inputgroup mb-3">
														<input
															type="password"
															className="form-control pe-5 password-input"
															placeholder={t('ConfirmPassword')}
															id="confirmPassword"
															value={confirmPassword}
															onChange={(e) => setConfirmPassword(e.target.value)}
														/>
														<button
															className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
															type="button"
															id="password-addon">
															<i className="ri-eye-fill align-middle"></i>
														</button>
													</div>
												</div>

												<div className="mt-4">
													<button className="btn btn-success w-100" type="button" onClick={forgetPasswordHandler}>
														{t('ChangePassword')}
													</button>
												</div>
											</form>
										</div>
									</div>
								}
							</div>
							<div className="mt-4 text-center">
								<p className="mb-0">
									{t('Do_Not_Have_Account')}
									<Link to="/auth/login" className="fw-semibold text-primary text-decoration-underline">
										{t('Back_To_Signin')}
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgetPasswordPage;
