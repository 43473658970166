import { createSlice } from '@reduxjs/toolkit';
import { IAttribute } from '../interface/attribute.interface';
import { addAttributeAction, deleteAttributeAction, getAttributeByIdAction, editAttributeAction, getAttributesAction } from '../actions/attribute.actions';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const SuccessAlert = withReactContent(Swal);

interface DataReducer {
	attributes: IAttribute[];
	status: REDUX_STATUS;
}

const initialState: DataReducer = {
	attributes: [],
	status: REDUX_STATUS.IDLE
};

const attributeSlice = createSlice({
	name: 'attribute',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAttributesAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(getAttributesAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				state.attributes = action.payload.data;
			})
			.addCase(getAttributesAction.rejected, (state, action: any) => {
				state.status = REDUX_STATUS.FAILED;
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})
			
			.addCase(addAttributeAction.fulfilled, (state) => {
				state.status = REDUX_STATUS.IDLE
			})
			.addCase(addAttributeAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})
			
			.addCase(deleteAttributeAction.fulfilled, (state) => {
				state.status = REDUX_STATUS.IDLE
			})
			.addCase(deleteAttributeAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})
			
			.addCase(getAttributeByIdAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			})
			
			.addCase(editAttributeAction.fulfilled, (state) => {
				state.status = REDUX_STATUS.IDLE
			})
			.addCase(editAttributeAction.rejected, (_, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			});
	}
});

export const attributeActions = attributeSlice.actions;
export default attributeSlice.reducer;
