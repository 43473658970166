import React from 'react';
import { Navigate } from 'react-router-dom';
import { useProfile } from '../provider/Profile.provider';
import { ROLE } from '../utility/enums/role.enum';

const AdminGuard = (props: { children: any; }) => {
	
	const userProfile = useProfile()

	if (userProfile.role?.type === ROLE.ADMIN) {
		return props.children;
	} else {
		return <Navigate to="/" replace />;
	}
};

export default AdminGuard;
