import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICartProduct, IOrder } from '../interfaces/order.interface';
import { Translation } from '../../../utility/helpers/translation.helper';
import { DISCOUNT_TYPE } from '../../../utility/enums/discount-type.enum';
import { IOfferItem } from '../../special-orders/interfaces/special-order.interface';

interface ISpecialOrderTable {
	details: IOrder;
}

export const SpecialOrderTableComponent: React.FC<ISpecialOrderTable> = ({ details }) => {
	const { t } = useTranslation();

	return (
		<table className="table table-nowrap align-middle table-borderless mb-0">
			<thead className="table-light text-muted text-start">
				<tr>
					<th scope="col">{t('ItemDetails')}</th>
					<th scope="col">{t('Price')}</th>
					<th scope="col">{t('Description')}</th>
				</tr>
			</thead>
			<tbody className="text-start">
				{details.special?.offers[0].items.map((item: IOfferItem) => (
					<tr key={item.item}>
						<td>
							<div className="d-flex">
								<div className="flex-grow-1 ms-3">
									<h5 className="fs-15">
										{item.item}
									</h5>
								</div>
							</div>
						</td>
						<td>{item.price} {t('CurrencyCode')}</td>
						<td>{item.weight}</td>
					</tr>
				))}
				<tr className="border-top border-top-dashed">
					<td colSpan={2}></td>
					<td colSpan={1} className="fw-medium p-0">
						<table className="table table-borderless mb-0">
							<tbody>
								<tr>
									<td>{t("Sub_Total")} :</td>
									<td className="text-end">
										{details.special.offers[0].total} {t('CurrencyCode')}
									</td>
								</tr>
								{details.paymentInfo?.offer && (
									<tr>
										<td>
											{t('Offer')} <span className="text-muted">({Translation(details.paymentInfo?.offer?.name)})</span> :
										</td>
										{details.paymentInfo?.offer?.discountType == DISCOUNT_TYPE.FIXED && (
											<td className="text-end">
												-{details.paymentInfo?.offer?.discountValue} {t('CurrencyCode')}
											</td>
										)}
										{details.paymentInfo?.offer?.discountType == DISCOUNT_TYPE.PERCENT && (
											<td className="text-end">
												-{(details.paymentInfo?.offer?.discountValue / 100) * details.cart?.totalPrice} {t('CurrencyCode')}
											</td>
										)}
									</tr>
								)}
								<tr>
									<td>{t('DeliveryCharge')} :</td>
									<td className="text-end">
										{details.paymentInfo?.deliveryFee} {t('CurrencyCode')}
									</td>
								</tr>
								<tr>
									<td>{t('Tax')} :</td>
									<td className="text-end">
										{details.paymentInfo?.tax} {t('CurrencyCode')}
									</td>
								</tr>
								<tr className="border-top border-top-dashed">
									<th scope="row">{t('Total')} ({t("CurrencyCode")}) :</th>
									<th className="text-end">
										{details.paymentInfo?.totalPrice} {t('CurrencyCode')}
									</th>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	);
};
