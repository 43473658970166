import { Api } from '../../../resources/api-constants';
import CustomAxios from '../../../utility/customAxios';
import { PaginationExtractor } from '../../../utility/helpers/pagination-extractor';

// Seller Service
export const listSellerPaymentsApi = (query: any) => {
	return CustomAxios.get(`${Api.SELLER_LIST_PAYMENTS}${PaginationExtractor(query)}`);
};
export const getSellerPaymentStatisticsService = () => {
	return CustomAxios.get(`${Api.SELLER_PAYMENTS_STATISTICS}`);
};

export const requestSellerPaymentsApi = () => {
	return CustomAxios.get(`${Api.SELLER_REQUEST_PAYMENTS}`);
};
